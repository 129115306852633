import React, { Component } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import Dropzone from 'react-dropzone'
import ptBR from 'date-fns/locale/pt-BR'
import { confirmAlert } from 'react-confirm-alert'
import Alert from 'react-s-alert'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'

import { AuthUserContext } from '../Session'
import FormInlineError from '../helpers/FormInlineError'
import PaypalButton from '../Payments/PayPalButton'
import { withFirebase } from '../firebase/context'
import { randomString } from '../helpers/UIHelpers'
import { quotes, quotesBackgrounds } from '../Quotes'
import { FuneralInfo, ImageAndMessage, MessageOnly, QuoteAndMessage } from '../Templates'

const SibApiV3Sdk = require('sib-api-v3-sdk')

const defaultClient = SibApiV3Sdk.ApiClient.instance

class Step3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateCreated: props.getStore().dateCreated,
      image: props.getStore().image,
      isSubmitting: false,
      message: props.getStore().message,
      quote: props.getStore().quote,
      quoteBackgroundColor: props.getStore().quoteBackgroundColor,
      quoteBackgroundImage: props.getStore().defaultQuoteBackgroundImage,
      quoteFontColor: props.getStore().quoteFontColor,
      selectedQuoteIndex: 0,
      sender: props.getStore().userName,
      tributeType: props.getStore().tributeType,
      userEmail: props.getStore().userEmail,
      userName: props.getStore().userName,
      venueAddress: props.getStore().venueAddress,
      venueDateTime: props.getStore().venueDateTime,
      venueName: props.getStore().venueName,
      shouldShowPaymentButton: false,
    }
    props.updateStore({
      ...this.state,
    })
    this.validateOnDemand = true
    this.validationCheck = this.validationCheck.bind(this)
    this.isValidated = this.isValidated.bind(this)
    this.validationErrors = this.validationErrors.bind(this)
  }

  componentDidMount() {
    registerLocale('ptBR', ptBR)
    setDefaultLocale('ptBR')
    const { isEditing } = this.props
    this.message.focus()
    if (isEditing) {
      this.loadTributeData()
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader()
    const $this = this
    reader.onload = e => {
      $this.setState({
        image: e.target.result,
        imageFile: acceptedFiles[0],
      })
      $this.validationCheck()
    }
    reader.readAsDataURL(acceptedFiles[0])
  }

  onMessageChange(message) {
    const { updateStore } = this.props
    const $this = this
    $this.setState({
      message,
    })
    updateStore({
      message,
    })
    setTimeout(() => {
      $this.validationCheck()
    }, 100)
  }

  onInputChange(text, input) {
    const { updateStore } = this.props
    this.setState({
      [input]: text,
    })
    updateStore({
      [input]: text,
    })
    this.validationCheck()
  }

  loadTributeData = () => {
    const { firebase, getStore, updateStore } = this.props
    const $this = this
    const { tributeId } = getStore()
    const { firestore } = firebase
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    firestore.settings(firestoreSettings)
    firestore
      .collection('Obituaries')
      .doc(tributeId)
      .get()
      .then(tributeDoc => {
        const {
          dateCreated,
          image,
          message,
          person,
          quote,
          quoteBackgroundColor,
          quoteBackgroundImage,
          quoteFontColor,
          sender,
          type,
          userEmail,
          venueAddress,
          venueDateTime,
          venueName,
        } = tributeDoc.data()
        updateStore({
          tributeType: type,
          ...tributeDoc.data(),
        })
        $this.setState({
          tributeType: type,
          ...tributeDoc.data(),
        })
        $this.message = message
        console.log(message)
        // this.onMessageChange(message)
      })
    // const promises = []
    // return new Promise(resolve => {
    //   let memorialData = {}
    //   const guestbookEntries = []
    //   memorialRef.get().then(doc => {
    //     const {
    //       available,
    //       guests,
    //       memorialBackgroundColor,
    //       memorialIntroduction,
    //       person,
    //       personPhoto,
    //       socialPosts,
    //       tributes,
    //     } = doc.data()

    //     $this.setState({ available })
    //     const personId = person.id

    //     firestore
    //       .collection('People')
    //       .doc(personId)
    //       .get()
    //       .then(personDoc => {
    //         const { personName, birthday, deathday } = personDoc.data()
    //         memorialData = {
    //           available,
    //           memorialBackgroundColor,
    //           memorialIntroduction,
    //           personBirthday: birthday.toDate(),
    //           personDeathday: deathday.toDate(),
    //           personName,
    //           personPhoto,
    //           socialPosts,
    //           tributes,
    //         }
    //         guests.map((guestbookEntryRef, index) =>
    //           promises.push(
    //             guestbookEntryRef.get().then(guestbookEntry => {
    //               const guestbookEntryData = guestbookEntry.data()
    //               guestbookEntryData.id = guestbookEntry.id
    //               promises.push(
    //                 guestbookEntryData.user.get().then(guestbookEntryUser => {
    //                   guestbookEntryData.user = {
    //                     name: guestbookEntryUser.data().name,
    //                     socialId: guestbookEntryUser.data().socialId,
    //                   }
    //                   guestbookEntries.push(guestbookEntryData)
    //                 })
    //               )
    //             })
    //           )
    //         )
    //         return Promise.all(promises)
    //       })
    //       .then(() => {
    //         memorialData.guests = guestbookEntries
    //         $this.setState(memorialData)
    //         updateStore(memorialData)
    //         resolve(memorialData)
    //       })
    //   })
    // })
  }

  handleDateTimeChange = dateTimeValue => {
    const { updateStore } = this.props
    updateStore({
      venueDateTime: dateTimeValue,
    })
    this.setState({
      venueDateTime: dateTimeValue,
    })
  }

  updateTribute = e => {
    const { tributeType } = this.state
    const { firebase, getStore } = this.props
    const { tributeId } = getStore()
    const data = getStore()
    const { firestore } = firebase
    if (this.isValidated()) {
      const docRef = firestore.collection('Obituaries').doc(tributeId)
      firestore.runTransaction(t =>
        t
          .get(docRef)
          .then(doc => {
            const obituaryData = {
              image: data.image || '',
              message: data.message || '',
              quote: data.quote || '',
              quoteBackgroundColor: data.quoteBackgroundColor || '',
              quoteBackgroundImage: data.quoteBackgroundImage || '',
              quoteFontColor: data.quoteFontColor || '',
              sender: data.sender || '',
              type: tributeType,
              venueAddress: data.venueAddress || '',
              venueDateTime: data.venueDateTime || null,
              venueName: data.venueName || '',
            }
            t.set(docRef, obituaryData, { merge: true })
          })
          .then(result => {
            confirmAlert({
              customUI: ({ onClose }) => (
                <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
                  <div className="box pa3 w-90 w-50-m w-40-l tc">
                    <h1 className="f3 mb4">Tributo alterado com sucesso.</h1>
                    <div className="flex w-100 flex-wrap justify-center">
                      <button
                        type="button"
                        className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                        onClick={() => {
                          onClose()
                        }}
                      >
                        Voltar ao Tributo
                      </button>
                    </div>
                  </div>
                </div>
              ),
            })
          })
      )
    }
  }

  persistData = e => {
    const { imageFile, tributeType } = this.state
    const { firebase, getStore } = this.props
    const { auth, firestore } = firebase
    const { companyId } = getStore()
    if (this.isValidated()) {
      const firestoreSettings = {
        timestampsInSnapshots: true,
      }
      firestore.settings(firestoreSettings)
      this.setState({
        isSubmitting: true,
      })
      let company = null
      if (auth.currentUser && companyId) {
        company = firestore.collection('Companies').doc(companyId)
      }

      const data = getStore()
      const personData = {
        available: true,
        birthday: null,
        city: data.personCity,
        company,
        deathday: null,
        memorial: null,
        obituaries: [],
        personName: data.personName,
        private: false,
      }
      const obituaryData = {
        dateCreated: {
          nanoseconds: 0,
          seconds: new Date().getTime() / 1000,
        },
        image: data.image || '',
        message: data.message || '',
        quote: data.quote || '',
        quoteBackgroundColor: data.quoteBackgroundColor || '',
        quoteBackgroundImage: data.quoteBackgroundImage || '',
        quoteFontColor: data.quoteFontColor || '',
        sender: data.sender || '',
        type: tributeType,
        venueAddress: data.venueAddress || '',
        venueDateTime: data.venueDateTime || null,
        venueName: data.venueName || '',
      }

      const assignPersonToCompany = person =>
        new Promise((resolve, reject) => {
          const companyRef = firestore.collection('Companies').doc(companyId)
          firestore.runTransaction(t =>
            t
              .get(companyRef)
              .then(doc => {
                const obj = doc.get('people') ? doc.get('people') : []
                obj.push(person)
                t.set(companyRef, { people: obj }, { merge: true })
              })
              .then(result => {
                resolve(result)
              })
          )
        })

      const addPerson = person =>
        new Promise((resolve, reject) => {
          firestore
            .collection('People')
            .add(person)
            .then(addedPerson => {
              // console.log(`Added person with ID: ${addedPerson.id}`)
              if (company) {
                assignPersonToCompany(addedPerson).then(() => {
                  resolve(addedPerson)
                })
              } else {
                resolve(addedPerson)
              }
            })
        })

      const updatePerson = person =>
        new Promise((resolve, reject) => {
          const personRef = firestore.collection('People').doc(person.personId)
          personRef
            .set(
              {
                obituaries: [person.obituary],
              },
              { merge: true }
            )
            .then(updatedPerson => {
              resolve(updatedPerson)
            })
        })

      const addObituary = person =>
        new Promise(resolve => {
          const mergedData = {
            ...obituaryData,
            person: firestore.collection('People').doc(person.id),
          }
          firestore
            .collection('Obituaries')
            .add(mergedData)
            .then(addedObituary => {
              // console.log(`Added obituary with ID: ${addedObituary.id}`)
              resolve({
                addedObituary,
                person,
              })
            })
        })

      const updateObituary = (obituaryId, userEmail) =>
        new Promise((resolve, reject) => {
          const obituaryRef = firestore.collection('Obituaries').doc(obituaryId)
          obituaryRef
            .set(
              {
                userEmail,
              },
              { merge: true }
            )
            .then(updatedObituary => {
              resolve(updatedObituary)
            })
        })

      const updateObituaryImage = (obituaryId, imagePath) =>
        new Promise(resolve => {
          const obituaryRef = firestore.collection('Obituaries').doc(obituaryId)
          obituaryRef
            .update({
              image: imagePath,
            })
            .then(ref => {
              resolve(ref)
            })
        })

      const uploadImage = (image, obituaryId) =>
        new Promise(resolve => {
          if (!image) {
            resolve()
          }
          const { storage } = firebase
          const storageRef = storage.ref()
          const imagePath = `site/tributes/${obituaryId}/${randomString(20)}`
          const imageRef = storageRef.child(imagePath)
          imageRef.put(imageFile).then(snapshot => {
            storageRef
              .child(imagePath)
              .getDownloadURL()
              .then(url => {
                updateObituaryImage(obituaryId, url).then(obituaryImageRef =>
                  resolve(obituaryImageRef)
                )
              })
          })
        })

      const sendMail = (person, password) =>
        new Promise(resolve => {
          const apiKey = defaultClient.authentications['api-key']
          apiKey.apiKey = process.env.GATSBY_SENDINBLUE_APIKEY
          const smtpApi = new SibApiV3Sdk.SMTPApi()
          const sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail()
          sendSmtpEmail.sender = {
            name: 'Immortalis',
            email: 'noreply@immortalis.com.br',
          }
          const personFirstName =
            person.name.indexOf(' ') > 0 ? person.name.split(' ')[0] : person.name
          sendSmtpEmail.subject = 'Seja bem-vindo ao Immortalis'
          sendSmtpEmail.htmlContent = `
            <p>Olá, ${personFirstName}. Seja bem-vindo ao Immortalis.</p>
            <p>Veja abaixo os seus dados de acesso:</p>
            <p>
              <b>Usuário: ${person.email}<b>
              <b>Senha: ${password}<b>
            </p>
            <p>Caso tenha qualquer dúvida, não hesite em <a href="https://immortalis.com.br/#contact">entrar em contato</a> conosco.</p>
          `
          sendSmtpEmail.textContent = `
            Seja bem-vindo ao Immortalis. Utilize as seguintes informaçõees para acessar sua conta:
            Usuário: ${person.email}
            Senha: ${password}
          `
          sendSmtpEmail.to = [{ email: person.email }]
          smtpApi.sendTransacEmail(sendSmtpEmail).then(
            emailData => {
              // console.log(`API called successfully. Returned data: ${emailData}`)
              resolve(emailData)
            },
            error => {
              resolve(false)
            }
          )
        })

      addPerson(personData).then(personKey => {
        const { image, userName } = this.state
        addObituary(personKey).then(ref => {
          updatePerson({
            personId: ref.person.id,
            obituary: ref.addedObituary,
          }).then(personRef => {
            uploadImage(image, ref.addedObituary.id).then(() => {
              auth.fetchSignInMethodsForEmail(data.userEmail).then(activeSignInMethods => {
                // New User
                if (!activeSignInMethods.length) {
                  const password = randomString(10)
                  auth.createUserWithEmailAndPassword(data.userEmail, password).then(user => {
                    updateObituary(ref.addedObituary.id, data.userEmail).then(obityary => {
                      const person = {
                        email: data.userEmail,
                        name: userName,
                        roles: [],
                        socialId: null,
                        uid: user.user.uid,
                      }
                      firebase.user(person).then(() => {
                        sendMail(person, password).then(() => {
                          window.location.href = '/confirmation'
                        })
                      })
                    })
                  })
                } else {
                  updateObituary(ref.addedObituary.id, data.userEmail).then(obityary => {
                    window.location.href = '/confirmation'
                  })
                }
              })
            })
          })
        })
        // eslint-disable-next-line no-console
      }, console.log)
    }
  }

  deleteTribute = () => {
    const { firebase, getStore } = this.props
    const { firestore } = firebase
    const data = getStore()
    const { tributeId } = data
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
          <div className="box pa3 w-90 w-50-m w-40-l tc">
            <h1>Excluir Tributo?</h1>
            <p className="mb4">
              Atenção: esta operação não possui retorno. Deseja excluir este tributo?
            </p>
            <div className="flex w-100 flex-wrap justify-between">
              <button
                type="button"
                className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                onClick={() => {
                  const batch = firestore.batch()
                  const obituaryRef = firestore.collection('Obituaries').doc(tributeId)
                  obituaryRef.get().then(obituaryDoc => {
                    const personRef = obituaryDoc.data().person
                    personRef.get().then(personDoc => {
                      const personObituaries = personDoc.data().obituaries
                      if (personObituaries.length === 1 && !personDoc.data().memorial) {
                        batch.delete(personRef)
                      } else {
                        const newPersonObituaries = []
                        personObituaries.map(personObituary => {
                          if (personObituary !== obituaryRef) {
                            newPersonObituaries.push(personObituary)
                          }
                        })
                        batch.set(personRef, { tributes: personObituaries }, { merge: true })
                      }
                      batch.delete(obituaryRef)
                      batch.commit().then(() => {
                        onClose()
                        Alert.success('Tributo excluído com sucesso', {
                          effect: 'stackslide',
                          position: 'bottom-left',
                        })
                        setTimeout(() => {
                          Alert.closeAll()
                          window.location.href = '/my-account/'
                        }, 3000)
                      })
                    })
                  })
                }}
              >
                Sim
              </button>
              <button
                type="button"
                className="mh2 w-100 w-auto-ns ml2-ns btn btn-primary-outline mb2 mb0-ns"
                onClick={onClose}
              >
                Não
              </button>
            </div>
          </div>
        </div>
      ),
    })
  }

  goToPreviousStep = e => {
    const { jumpToStep } = this.props
    e.preventDefault()
    jumpToStep(1)
  }

  validationErrors = val => {
    const {
      imageValid,
      messageValid,
      quoteValid,
      venueAddressValid,
      venueDateTimeValid,
      venueNameValid,
    } = val
    const errMsgs = {
      quoteErrorMsg: quoteValid ? '' : 'Selecione uma citação.',
      imageErrorMsg: imageValid ? '' : 'Selecione uma imagem para enviar.',
      messageErrorMsg: messageValid ? '' : 'Digite a mensagem que deseja publicar no obituário.',
      venueAddressErrorMsg: venueAddressValid ? '' : 'Digite o endereço do local.',
      venueDateTimeErrorMsg: venueDateTimeValid ? '' : 'Selecione o dia e horário do evento.',
      venueNameErrorMsg: venueNameValid
        ? ''
        : 'Digite o nome do local onde será realizado o evento.',
    }
    return errMsgs
  }

  validateData(data) {
    const { tributeType } = this.state
    return {
      imageValid:
        tributeType !== 'ImageAndMessage' || (tributeType === 'ImageAndMessage' && !!data.image),
      messageValid: data.message !== '',
      quoteValid:
        tributeType !== 'QuoteAndMessage' || (tributeType === 'QuoteAndMessage' && !!data.quote),
      venueAddressValid:
        tributeType !== 'FuneralInfo' || (tributeType === 'FuneralInfo' && data.venueAddress),
      venueDateTimeValid:
        tributeType !== 'FuneralInfo' || (tributeType === 'FuneralInfo' && data.venueDateTime),
      venueNameValid:
        tributeType !== 'FuneralInfo' || (tributeType === 'FuneralInfo' && data.venueName),
    }
  }

  isValidated() {
    const userInput = this.grabUserInput()
    const validateNewInput = this.validateData(userInput)
    const { getStore, updateStore } = this.props
    let isDataValid = false

    if (Object.keys(validateNewInput).every(k => validateNewInput[k] === true)) {
      if (getStore().message !== this.grabUserInput().message) {
        updateStore({
          ...userInput,
          savedToCloud: false,
        })
      }
      isDataValid = true
    } else {
      this.setState(
        Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
      )
    }
    return isDataValid
  }

  grabUserInput() {
    const { image, message, quote, venueDateTime } = this.state
    return {
      image,
      message,
      quote,
      venueAddress: this.venueAddress ? this.venueAddress.value : null,
      venueDateTime,
      venueName: this.venueName ? this.venueName.value : null,
    }
  }

  validationCheck() {
    if (!this.validateOnDemand) return
    const { isEditing } = this.props
    const userInput = this.grabUserInput()
    const validateNewInput = this.validateData(userInput)
    this.setState(
      Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
    )
    if (!isEditing) {
      this.setState({
        shouldShowPaymentButton: Object.keys(validateNewInput).every(
          k => validateNewInput[k] === true
        ),
      })
    }
  }

  selectQuoteBackground(obj, index) {
    const { updateStore } = this.props
    const stateObj = {
      quoteBackgroundColor: obj.backgroundColor,
      quoteBackgroundImage: obj.backgroundImage,
      quoteFontColor: obj.fontColor,
      selectedQuoteIndex: index,
    }
    this.setState(stateObj)
    updateStore(stateObj)
    this.validationCheck()
  }

  render() {
    const {
      image,
      imageErrorMsg,
      imageValid,
      message,
      messageErrorMsg,
      messageValid,
      quote,
      quoteBackgroundColor,
      quoteBackgroundImage,
      quoteErrorMsg,
      quoteFontColor,
      quoteValid,
      selectedQuoteIndex,
      sender,
      shouldShowPaymentButton,
      venueAddress,
      venueAddressErrorMsg,
      venueAddressValid,
      venueDateTime,
      venueDateTimeErrorMsg,
      venueDateTimeValid,
      venueName,
      venueNameErrorMsg,
      venueNameValid,
    } = this.state
    const { getStore, isEditing, updateStore } = this.props
    const { tributeType } = getStore()
    const verb = isEditing ? 'Gerenciamento' : 'Criação'

    const shouldShowCemitery = ['FuneralInfo'].includes(tributeType)
    const shouldShowDateTime = ['FuneralInfo'].includes(tributeType)
    const shouldShowImageError = imageValid === false
    const shouldShowMessageError = messageValid === false
    const shouldShowPhoto = ['ImageAndMessage'].includes(tributeType)
    const shouldShowQuote = ['QuoteAndMessage'].includes(tributeType)
    const shouldShowQuoteError = quoteValid === false
    const shouldShowVenueAddressError = venueAddressValid === false
    const shouldShowVenueDateTimeError = venueDateTimeValid === false
    const shouldShowVenueNameError = venueNameValid === false

    return (
      <section className="box tc ph2 w-100 pa3 pa4-ns mb3">
        <Alert />
        <div className="mw8-ns center mt3-ns">
          <form>
            <h2 className="ma0 mv3 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
              {tributeType === 'MessageOnly' && <div>{verb} de Tributo com Mensagem</div>}
              {tributeType === 'ImageAndMessage' && (
                <div>{verb} de Tributo com Mensagem e Imagem</div>
              )}
              {tributeType === 'QuoteAndMessage' && (
                <div>{verb} de Tributo com Mensagem e Citação</div>
              )}
              {tributeType === 'FuneralInfo' && (
                <div>{verb} de Tributo dados de Velório e Sepultamento</div>
              )}
            </h2>
            <div className="flex flex-wrap">
              <div className="pa2 pa3-ns w-100 w-50-ns">
                <h3 className="mt0 dn db-ns">Conteúdo</h3>
                {shouldShowQuote && [
                  <div key="quote-text" className="mb3 w-100">
                    <div className={`dropdown ${shouldShowQuoteError ? 'has-error' : ''}`}>
                      <select
                        id="quote"
                        className="filled pr4"
                        onChange={e => {
                          this.setState({ quote: e.target.options[e.target.selectedIndex].text })
                          updateStore({
                            quote: e.target.options[e.target.selectedIndex].text,
                          })
                          const $this = this
                          setTimeout(() => {
                            $this.validationCheck()
                          }, 100)
                        }}
                      >
                        <option value="" />
                        {quotes.map((quoteGroup, idxQuoteGroup) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <optgroup key={`quoteGroup-${idxQuoteGroup}`} label={quoteGroup.type}>
                            {quoteGroup.quotes.map((quoteItem, idxQuote) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <option key={`quote-${idxQuote}`} value={quoteItem}>
                                {quoteItem}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </select>
                      <label htmlFor="quote">Citação</label>
                      {shouldShowQuoteError && <FormInlineError error={quoteErrorMsg} />}
                    </div>
                  </div>,
                  <div
                    id="quote-background"
                    key="quote-background"
                    className="mb3 w-100 tl relative bw1 b--mid-gray br2 pa2 ba"
                  >
                    <label htmlFor="quote-background" className="db f7 dark-gray">
                      Fundo da Citação
                    </label>
                    <ul className="ma0 list pa0 nowrap overflow-x-scroll">
                      {quotesBackgrounds.map((quotesBackground, idx) => (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <li
                          // eslint-disable-next-line react/no-array-index-key
                          key={idx}
                          className={`
                                cover pointer dib ba br1 b--mid-gray w3 h3 bg-blue
                                ${idx === 0 ? 'mr1 mv1' : 'ma1'}
                                ${selectedQuoteIndex === idx ? 'ba bw2 b--orange' : ''}
                              `}
                          style={{
                            backgroundColor: quotesBackground.backgroundColor,
                            fontColor: quotesBackground.fontColor,
                            backgroundImage: quotesBackground.backgroundImage
                              ? `url(${quotesBackground.backgroundImage})`
                              : null,
                          }}
                          onKeyDown={() => {
                            this.selectQuoteBackground(quotesBackgrounds[idx], idx)
                          }}
                          onClick={() => {
                            this.selectQuoteBackground(quotesBackgrounds[idx], idx)
                          }}
                        />
                      ))}
                    </ul>
                  </div>,
                ]}
                <div className="mb3 w-100">
                  <div className={`textarea ${shouldShowMessageError ? 'has-error' : ''}`}>
                    <textarea
                      ref={c => {
                        this.message = c
                      }}
                      id="message"
                      value={message}
                      className="filled lh-copy f6"
                      onBlur={this.validationCheck}
                      onChange={e => {
                        this.onMessageChange(e.target.value)
                      }}
                    />
                    <label htmlFor="message">Mensagem</label>
                    {shouldShowMessageError && <FormInlineError error={messageErrorMsg} />}
                  </div>
                </div>
                {shouldShowCemitery && [
                  <div key="venueName" className="mb3 w-100">
                    <div className={`input ${shouldShowVenueNameError ? 'has-error' : ''}`}>
                      <input
                        ref={c => {
                          this.venueName = c
                        }}
                        id="venueName"
                        className={`${venueName !== '' ? 'filled' : ''}`}
                        onBlur={this.validationCheck}
                        onChange={e => {
                          this.onInputChange(e.target.value, e.target.id)
                        }}
                      />
                      <label htmlFor="venueName">Cemitério / Sala de Velório</label>
                      {shouldShowVenueNameError && <FormInlineError error={venueNameErrorMsg} />}
                    </div>
                  </div>,
                  <div key="venueAddress" className="mb3 w-100">
                    <div className={`input ${shouldShowVenueAddressError ? 'has-error' : ''}`}>
                      <input
                        ref={c => {
                          this.venueAddress = c
                        }}
                        id="venueAddress"
                        className={`${venueAddress !== '' ? 'filled' : ''}`}
                        onBlur={this.validationCheck}
                        onChange={e => {
                          this.onInputChange(e.target.value, e.target.id)
                        }}
                      />
                      <label htmlFor="venueAddress">Endereço do Cemitério / Velório</label>
                      {shouldShowVenueAddressError && (
                        <FormInlineError error={venueAddressErrorMsg} />
                      )}
                    </div>
                  </div>,
                ]}
                {shouldShowDateTime && (
                  <div className="mb3 w-100 relative">
                    <DatePicker
                      selected={venueDateTime}
                      onChange={this.handleDateTimeChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat={`d 'de' MMMM 'de' yyyy, 'às' H:mm`}
                      timeCaption="Hora"
                      locale="ptBR"
                      placeholderText="Data e Horário"
                      className={`b--solid bw1 br2 ${
                        shouldShowVenueDateTimeError ? 'b--red' : 'b--mid-gray'
                      }`}
                    />
                    {shouldShowVenueDateTimeError && (
                      <FormInlineError error={venueDateTimeErrorMsg} />
                    )}
                  </div>
                )}
                {shouldShowPhoto && (
                  <div className="mb3 w-100">
                    <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          {...getRootProps()}
                          className={`relative pointer b--dashed br3 bw1 outline-0 ${
                            isDragActive ? 'b--red' : ''
                          } ${shouldShowImageError ? 'b--red' : 'b--mid-gray'}`}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Solte seu arquivo aqui...</p>
                          ) : (
                            <p>Clique aqui para adicionar uma foto&hellip;</p>
                          )}
                          {shouldShowImageError && <FormInlineError error={imageErrorMsg} />}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                )}
                <div className="mb3 w-100">
                  <div className="input">
                    <input
                      id="sender"
                      type="text"
                      value={sender || ' '}
                      className="filled"
                      onChange={event => {
                        this.setState({ [event.target.id]: event.target.value })
                        updateStore({
                          [event.target.id]: event.target.value,
                        })
                        this.validationCheck()
                      }}
                    />
                    <label htmlFor="sender">Enviado Por</label>
                  </div>
                </div>
              </div>
              <div className="pa2 pa3-ns w-100 w-50-ns">
                <h3 className="mt0">Visualização</h3>
                {tributeType === 'FuneralInfo' && (
                  <FuneralInfo
                    message={message}
                    sender={sender}
                    venueName={venueName}
                    venueAddress={venueAddress}
                    venueDateTime={venueDateTime}
                  />
                )}
                {tributeType === 'ImageAndMessage' && (
                  <ImageAndMessage message={message} sender={sender} image={image} />
                )}
                {tributeType === 'QuoteAndMessage' && (
                  <QuoteAndMessage
                    quote={quote}
                    message={message}
                    sender={sender}
                    quoteBackgroundColor={quoteBackgroundColor}
                    quoteBackgroundImage={quoteBackgroundImage}
                    quoteFontColor={quoteFontColor}
                  />
                )}
                {tributeType === 'MessageOnly' && <MessageOnly message={message} sender={sender} />}
              </div>
            </div>
            <div className="mt3 flex flex-wrap justify-between flex-column-reverse flex-row-ns pa2 pa3-ns">
              <div className="w-100 w-50-ns tl">
                {isEditing && (
                  <button
                    type="button"
                    className="btn btn-primary btn-small btn-primary-outline lh-title w-auto"
                    onClick={e => this.deleteTribute(e)}
                  >
                    Excluir Tributo
                  </button>
                )}
                {!isEditing && (
                  <button
                    type="button"
                    className="btn btn-primary btn-small btn-primary-outline lh-title w-auto"
                    onClick={e => this.goToPreviousStep(e)}
                  >
                    Alterar Tipo de Publicação
                  </button>
                )}
              </div>
              <AuthUserContext.Consumer>
                {authUser => {
                  const isAdmin = authUser && authUser.roles.includes('admin')
                  const isChurchAdmin = authUser && authUser.roles.includes('admin-church')
                  const price = isChurchAdmin ? 29.95 : 59.9
                  return (
                    <div className="w-100 w-50-ns w-100 mb2 mb0-ns tr">
                      {shouldShowPaymentButton && (
                        <div className="tc">
                          {isAdmin && (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.persistData()}
                            >
                              Salvar Tributo
                            </button>
                          )}
                          {!isAdmin && (
                            <div>
                              <PaypalButton price={price} onClick={() => this.persistData()} />
                              <small className="db mv2 gray">
                                ...e seu tributo será publicado em instantes.
                                <br />
                                <small className="db mv3 gray b">
                                  Cartões aceitos: Visa, Mastercard ou American Express
                                </small>
                              </small>
                            </div>
                          )}
                        </div>
                      )}
                      {isEditing && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => this.updateTribute()}
                        >
                          Salvar Tributo
                        </button>
                      )}
                      {!isEditing && !shouldShowPaymentButton && (
                        <div
                          className="justify-center flex items-center f6 ba br2 b--mid-gray pa2 gray"
                          style={{
                            height: '45px',
                          }}
                        >
                          Preencha os campos para finalizar a criação do tributo...
                        </div>
                      )}
                    </div>
                  )
                }}
              </AuthUserContext.Consumer>
            </div>
          </form>
        </div>
      </section>
    )
  }
}

export default withFirebase(Step3)
